import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import AddWorkerForm from 'components/AddWorkerForm'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import Info from 'components/Info'
import {
  AddSubTaskMutationFn,
  AddTaskMutationFn,
  GetProjectsByCompanyQuery,
  Task,
  User,
} from 'generated/graphql'
import withMui from 'hoc/withMuiTheme'
import { useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { GoProject } from 'react-icons/go'
import { IoAddCircle } from 'react-icons/io5'
import { MdExpandCircleDown } from 'react-icons/md'
import { themeColor } from 'utils/theme'
import './TaskForm.styles.scss'
interface GroupedSubTasks {
  [subProjectName: string]: Task[] // Or use [subProjectId: string] if grouping by ID
}
function TaskForm({
  usersArray,
  theme,
  addTask,
  companyId,
  addSubTask,
  projectsArray,
  onSuccess,
  addTaskLoading,
  addSubTaskLoading,
  projectId,
}: {
  usersArray: User[]
  theme?: string
  addTask: AddTaskMutationFn
  addSubTask?: AddSubTaskMutationFn
  companyId: string
  projectsArray?: GetProjectsByCompanyQuery
  onSuccess: () => void
  addTaskLoading?: boolean
  addSubTaskLoading?: boolean
  projectId?: string
}) {
  const [taskName, setTaskName] = useState('')
  const [beginTime, setBeginTime] = useState<Date>(new Date())
  const [estimatedTime, setEstimatedTime] = useState<Date>(new Date())
  const [loading, setLoading] = useState(false)
  const [showUsers, setShowUsers] = useState(false)
  const [workers, onAddWorker] = useState<User[]>([])
  const [project, onAddProject] = useState<string>('')
  const [validationError, setValidationError] = useState('')
  const [success, setSuccess] = useState(false)
  const [selectedTask, setSelectedTask] = useState('')
  const [operationType, setOperationType] = useState('Task')
  const [groupedSubTasks, setGroupedSubTasks] = useState<GroupedSubTasks>({})
  useEffect(() => {
    if (projectsArray?.getProjectsByCompany?.length) {
      const groupedSubTasks = projectsArray.getProjectsByCompany.reduce(
        (acc: any, project) => {
          project?.subProjects?.forEach((subProject) => {
            const subProjectName = subProject?.name
            if (!subProjectName || !subProject.tasks) return

            // Initialize the subproject group if it doesn't exist
            if (!acc[subProjectName]) {
              acc[subProjectName] = []
            }

            // Push tasks to the appropriate subproject group
            acc[subProjectName].push(...subProject.tasks)
          })

          return acc
        },
        {}
      )
      setGroupedSubTasks(groupedSubTasks)

    }
  }, [projectsArray?.getProjectsByCompany])

  // Form validation function
  const validateForm = () => {
    setValidationError('')
    if (!taskName.trim()) {
      setValidationError('Please enter a task name')

      return false
    }

    if (operationType === 'Task' && !projectId && !project) {
      setValidationError('Please select a project')

      return false
    }

    return true
  }

  function handleTaskSelectChange(e: SelectChangeEvent) {
    setValidationError('')
    onAddProject(e.target.value)
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (validateForm()) {
          setLoading(true)
          if (operationType === 'Task') {
            addTask({
              variables: {
                projectId: projectId
                  ? projectId
                  : (projectsArray?.getProjectsByCompany
                      ?.flatMap((r) => r?.subProjects)
                      ?.find((r) => r?.name === project)?._id as string),
                taskName,
                startTid: beginTime,
                estimatedFinishTime: estimatedTime,
                workers: [...workers.map((r) => r.id as string)],
              },
              onCompleted: () => {
                onSuccess()
                setSuccess(true)
              },
            })
          }
          if (operationType === 'Sub task' && addSubTask) {
            addSubTask({
              variables: {
                subTaskName: taskName,
                taskName: selectedTask,
                startTid: beginTime,
                estimatedFinishTime: estimatedTime,
                workers: [...workers.map((r) => r.id as string)],
              },
              onCompleted: () => {
                onSuccess()
                setSuccess(true)
              },
            })
          }
        }
      }}
      className="task-form-wrapper"
    >
      <FormGroup row>
        {['Task', 'Sub task'].map((roleItem, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                onChange={(r) => {
                  setOperationType(r.target.value)
                  setValidationError('')
                }}
                checked={operationType === roleItem}
                name={roleItem}
                value={roleItem}
              />
            }
            label={
              <div style={{ color: theme === 'dark' ? '#fff' : '#383838' }}>
                {roleItem}
              </div>
            }
          />
        ))}
      </FormGroup>
      {operationType === 'Sub task' && (
        <Grid item xs={12} style={{ marginBottom: '15px' }} sm={4} width="100%">
          <FormControl fullWidth>
            <InputLabel
              sx={{ color: 'white', opacity: '0.8' }}
              id="demo-simple-select-label"
            >
              Parent task
            </InputLabel>
            <Select
              sx={{ width: '100%', color: 'white' }}
              value={selectedTask || ''}
              onChange={(res) => {
                setSelectedTask(res.target.value as string)
                setValidationError('')
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Parent task"
            >
              {Object.entries(groupedSubTasks).flatMap(
                ([subProjectName, tasks]) => [
                  <ListSubheader
                    key={`${subProjectName}-header`}
                    sx={{ backgroundColor: themeColor,color:'#383838' ,letterSpacing:'2px'}}
                  >
                    <div
                      className="place-middle"
                      style={{
                        width: 'fit-content',
                        marginBottom: '1rem',
                        marginTop: '1rem',
                        fontWeight:'bold'
                      }}
                    >
                      <GoProject
                        style={{ marginRight: '0.5rem' }}
                        color='black'
                        
                      />
                      <span>{subProjectName}</span>
                    </div>
                  </ListSubheader>,
                  ...tasks.map((taskItem) => (
                    <MenuItem
                      key={taskItem._id}
                      value={taskItem.name as string}
                    >
                      {taskItem.name}
                    </MenuItem>
                  )),
                ]
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid container spacing={2} direction="column">
           {operationType === 'Task' && !projectId ? (
          <Grid item xs={12} sm={4}>
            <div className="task-form-wrapper__select">
              <FormControl fullWidth>
                <InputLabel
                  sx={{ color: 'white', opacity: '0.8' }}
                  id="demo-simple-select-label"
                >
                  Projekt
                </InputLabel>
                <Select
                  sx={{ width: '100%', color: 'white' }}
                  value={project || ''}
                  onChange={handleTaskSelectChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="SubProject"
                >
                  {projectsArray &&
                    projectsArray.getProjectsByCompany &&
                    projectsArray.getProjectsByCompany.flatMap((project) =>
                      project!.subProjects
                        ? project!.subProjects.map((subProject) => (
                            <MenuItem
                              key={subProject?._id as string}
                              value={subProject?.name as string}
                            >
                              {subProject?.name as string}
                            </MenuItem>
                          ))
                        : null
                    )}
                </Select>
              </FormControl>
            </div>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={4}>
          <TextField
            label="Aktivitets namn"
            variant="outlined"
            value={taskName}
            onChange={(e) => {
              setTaskName(e.target.value)
              setValidationError('')
            }}
            InputLabelProps={{
              shrink: true,
              style: { color: 'white' },
            }}
            inputProps={{ style: { color: 'white' } }}
            fullWidth
            placeholder="Lägg till namn"
            type="text"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DatePicker
            label="Starttid"
            value={beginTime as Date}
            defaultValue={beginTime}
            handleChange={(val: Date) => setBeginTime(val)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DatePicker
            label="Uppskattad tid"
            value={estimatedTime as Date}
            defaultValue={estimatedTime}
            handleChange={(val: Date) => {
              setEstimatedTime(val)
              setValidationError('')
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} width="100%">
          {workers!.length > 0 ? (
            <div style={{ paddingLeft: '1em' }}>
              <label className="label-container">
                <span>Personal:</span>
              </label>
              <div>
                {workers!.length > 0
                  ? workers.map((r: User) => {
                      return (
                        <div key={r.id}>
                          <span>
                            <FaUser
                              style={{ marginRight: '3px', opacity: '0.7' }}
                            />
                          </span>
                          <span>{r?.username as string}</span>
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
          ) : null}

          <Accordion
            slotProps={{ transition: { timeout: 400 } }}
            expanded={showUsers}
            onChange={() => {
              setShowUsers(!showUsers)
              setValidationError('')
            }}
            sx={{
              color: 'white',
              border: `1px solid ${'#49b4b862'}`,
              backgroundColor: 'transparent',
              '& .MuiAccordion-region': { height: showUsers ? 'auto' : 0 },
            }}
          >
            <AccordionSummary
              expandIcon={<MdExpandCircleDown size={34} />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Lägg till personal
            </AccordionSummary>
            <Fade in={showUsers} timeout={400}>
              <AccordionDetails>
                <AddWorkerForm
                  usersArray={usersArray}
                  theme={theme}
                  onAdd={onAddWorker}
                  workers={workers}
                />
              </AccordionDetails>
            </Fade>
          </Accordion>
        </Grid>

     
      </Grid>

      {validationError ? <Info type="error">{validationError}</Info> : null}
      {success ? <Info type="success">Lyckades</Info> : null}
      <Button
        type="submit"
        width="100%"
        label="Lägg till"
        loading={addTaskLoading || success}
        icon={<IoAddCircle color="#383838" size={23} />}
      />
    </form>
  )
}

export default withMui(TaskForm)
