import { DayInfo, DayInfoData } from "types/sharedTypes";
const fullDay = 8;
const computeLeave = (day: DayInfo, acc: DayInfoData) => {
    const type = day.hasIntersection
        ? day.intersectionLeaveType
        : day.data?.leaveType;

    let leaveSpended = day?.data
        ? day.hasIntersection
            ? parseInt((day?.leaveSpended as string).split(":")[0])
            : parseInt((day.data?.timeSpended as string).split(":")[0])
        : 0;
    let leaveSpendedMinutes = day?.data
        ? day.hasIntersection
            ? parseInt((day?.leaveSpended as string).split(":")[1])
            : parseInt((day.data?.timeSpended as string).split(":")[1])
        : 0;

    switch (type as string) {

        case "Semester":
            acc.totalSemester += leaveSpended;
            acc.semesterMinutes += leaveSpendedMinutes;
            acc.semester += leaveSpended;
            if (acc.semester >= fullDay) {
                acc.semesterdagar += 1;
                acc.semester -= fullDay;
            }
            if (acc.semesterMinutes >= 60) {
                acc.semester += 1;
                acc.totalSemester += 1;
                acc.semesterMinutes -= 60;
            }
            acc.totalSemesterHoursMinutes = [acc.totalSemester, acc.semesterMinutes]
            break;
        case "Sjuk":
            acc.sjukMinutes += leaveSpendedMinutes;
            acc.Sjuk += leaveSpended;
            acc.TotalSjuk += leaveSpended;
            acc.totalSjukHoursMinutes[1] += leaveSpendedMinutes
            if (acc.Sjuk >= fullDay) {
                acc.Sjukdagar += 1;
                acc.Sjuk -= fullDay;
            }
            if (acc.sjukMinutes >= 60) {
                acc.Sjuk += 1;
                acc.TotalSjuk += 1;
                acc.sjukMinutes -= 60;

            }
            acc.totalSjukHoursMinutes = [acc.TotalSjuk, acc.sjukMinutes]
            break;
        case "Tjenstledighet":
            acc.tjänstledighet += leaveSpended;
            acc.tjänstledighetMinutes += leaveSpendedMinutes;
            acc.TotalTjänstledighet += leaveSpended;
            acc.totalTjänstledighetHoursMinutes[1] += leaveSpendedMinutes

            if (acc.tjänstledighet >= fullDay) {
                acc.tjänstledighetdagar += 1;
                acc.tjänstledighet -= fullDay;
            }
            if (acc.tjänstledighetMinutes >= 60) {
                acc.tjänstledighet += 1;
                acc.TotalTjänstledighet += 1;
                acc.tjänstledighetMinutes -= 60;

            }

            acc.totalTjänstledighetHoursMinutes = [acc.TotalTjänstledighet, acc.tjänstledighetMinutes]
            break;
        case "Vabb":
            acc.Vabb += leaveSpended;
            acc.vabbMinutes += leaveSpendedMinutes;
            acc.totalVabb += leaveSpended;
            if (acc.Vabb >= fullDay) {
                acc.Vabbdagar += 1;
                acc.Vabb -= fullDay;

            }
            if (acc.vabbMinutes >= 60) {
                acc.Vabb += 1;
                acc.totalVabb += 1;
                acc.vabbMinutes -= 60;
            }
            acc.totalVabbHoursMinutes = [acc.totalVabb, acc.vabbMinutes]
            break;
        default:
            break;
    }
};
export default computeLeave;