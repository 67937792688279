import Button from 'components/Button'
import Modal from 'components/Modal'
interface ConfirmationModalProps {
  modalIsOpen: boolean
  setModalIsOpen: (val: boolean) => void
  onConfirm: () => void, onNotConfirm: () => void
  message: string
}
export default function ConfirmationModal({
  modalIsOpen,
  setModalIsOpen,
  onConfirm,
  message,
  onNotConfirm
}: ConfirmationModalProps) {
  return (
    <Modal
      isModalOpen={modalIsOpen}
      setIsModalOpen={() => !modalIsOpen}
      width="fit-content"
    >
      <div style={{ width: '300px' }} className="plce-middle">
        {' '}
        <div>{message}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '1rem',
          }}
          className="place-middle"
        >
          <Button
            width="40%"
            label="Ja"
            onClick={onConfirm}
          ></Button>
          <Button
            width="40%"
            label="Nej"
            onClick={onNotConfirm}
          ></Button>
        </div>
      </div>
    </Modal>
  )
}
