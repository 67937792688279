import Label from 'components/Label'
import WeekCalendar from 'components/WeekCalendar'
import { useTheme } from 'hooks/theme.hook'
import { BsCalendarMonth } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import routes from 'utils/routes.json'
import './WeekReport.scss'

const WeekReport = () => {
  const themeObj = useTheme()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  return (
    <div className="week-rapport-container">
      <Label label={routes.report_week.component} />
      <div style={{ position: 'relative', width: '100%', paddingTop: '10px' }}>
        {' '}
        <div className="month-report__week-icon">
          <span
            onClick={() =>
              navigate(`/report/month/?date=${searchParams.get('date')||new Date().toISOString()}`)
            }
          >
             <span style={{ color: themeObj?.theme==='dark'?"#fff":'#282828',fontWeight:'bold' }}>Månadsrapport</span>
           
            <BsCalendarMonth className="icon" color="inherit" size={25}/>
          </span>
        </div>
      </div>
      <WeekCalendar
        theme={themeObj?.theme as string}
        date={searchParams.get('date') || new Date().toISOString()}
      />
    </div>
  )
}

export default WeekReport
