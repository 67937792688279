import AddWorkerForm from 'components/AddWorkerForm'
import BackButton from 'components/BackButton/BackButton'
import Button from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import Info from 'components/Info'
import Label from 'components/Label'
import ModalComponent from 'components/Modal'
import {
  GetTaskByNameQuery,
  User,
  useAddUserToTaskMutation,
  useDeActivateSubTaskMutation,
  useGetProjectByNameLazyQuery,
  useGetTaskByNameLazyQuery,
  useGetUsersByCompanyLazyQuery,
} from 'generated/graphql'
import useAuth from 'hooks/Auth.hook'
import { useEffect, useState } from 'react'
import { AiFillProject } from 'react-icons/ai'
import { BsTrash } from 'react-icons/bs'
import { FaTasks } from 'react-icons/fa'
import { HiUser, HiUsers } from 'react-icons/hi'
import { IoAdd, IoPersonAdd } from 'react-icons/io5'
import { MdTask } from 'react-icons/md'
import { VscProject } from 'react-icons/vsc'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { themeColor } from 'utils/theme'
import './Task.scss'

const Task = () => {
  const [getTasks, { refetch }] = useGetTaskByNameLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [task, setTask] = useState<GetTaskByNameQuery>()
  const [searchParams] = useSearchParams()
  const { user } = useAuth()
  const [workersModal, openWorkersModal] = useState(false)
  const { task: taskName, project: projectName } = useParams()
  const [subTaskDeactivated, setSubTaskDeactivated] = useState(false)
  const [workers, onAddWorker] = useState<User[]>()
  const [deleteTaskModal, setDeleteTaskModal] = useState(false)
  const [getUsers, { data: usersArray }] = useGetUsersByCompanyLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [getProjectInfo] = useGetProjectByNameLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [deactivSubTask,{error:deactivateSubTaskError}] = useDeActivateSubTaskMutation({
    fetchPolicy: 'network-only',
  })
  const navigate = useNavigate()
  const [addUserSuccess, setAddUserSuccess] = useState(false)
  const [
    addUserToTaskMutate,
    { data: addedUserData, loading: addedUserLoading },
  ] = useAddUserToTaskMutation({ fetchPolicy: 'network-only' })

  useEffect(() => {
    getTasks({
      variables: { taskName: taskName as string },
      onCompleted: (res) => {
        setTask(res)
      },
    })
  }, [taskName, addedUserData])

  useEffect(() => {
    if (workersModal && user?.currentUser) {
      getUsers({
        variables: { companyId: user?.currentUser.company?._id as string },
      })
    }
  }, [workersModal])

  useEffect(() => {
    onAddWorker(workers as User[])
  }, [usersArray?.getUsersByCompany, task?.getTaskByName?.workers])

  function addUserToTask(taskId: string, workers: string[]) {
    const subProject = searchParams.get('subProject')
    const project = searchParams.get('project')
    if (!subProject) return
    getProjectInfo({
      variables: { projectName: subProject as string },
      onCompleted: (project) => {
        addUserToTaskMutate({
          variables: {
            projectId: project.getProjectByName?._id as string,
            taskId: taskId,
            users: workers,
          },

          onCompleted: () => {
            setAddUserSuccess(true)
            refetch()
            setTimeout(() => {
              openWorkersModal(false)
              setAddUserSuccess(false)
            }, 2000)
          },
        })
      },
    })
  }
  useEffect(() => {
    onAddWorker(task?.getTaskByName?.workers as User[])
  }, [task?.getTaskByName])
  return (
    <div className="task-screen">
      <BackButton
        onNavigate={() =>
         navigate(`/dashboard/detail/?${searchParams.toString()}`)
        }
      />
      <Label label="Aktivitet Info"></Label>
      <h2 className="task-header-parent">
        <FaTasks color={themeColor} size={23} />
        <span>{taskName}</span>
      </h2>

      <div className="content-wrapper">
        <div className="add-buttons-container">
          <div className="add-buttons-container__icon">
            <IoPersonAdd
              className="project-screen__workers-parent__icon"
              onClick={() => openWorkersModal(true)}
              size={34}
              color="#49b4b8"
            />
          </div>
        </div>

        <div className="task-screen__workers-parent">
          <div>
            <h3
              style={{
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span className="task-header-parent">
                <VscProject color={themeColor} size={23} />
                <span>{projectName}</span>
              </span>
            </h3>
          </div>

          {task?.getTaskByName?.subTasks?.map((r) => {
            return (
              <div
                key={r?._id}
                className="place-middle task-screen__sub-task-item"
              >
                <ConfirmationModal
                  onConfirm={() =>
                    deactivSubTask({
                      variables: { subTaskId: r?._id as string },
                      onCompleted: () => {
                        setDeleteTaskModal(false)
                        setSubTaskDeactivated(true)
                        getTasks({
                          variables: { taskName: taskName as string },
                          onCompleted: (res) => {
                            setTask(res)
                            setTimeout(() => {
                              setSubTaskDeactivated(false)
                            }, 1500)
                          },
                        })
                      },
                      onError: (err) => console.log(err),
                    })
                  }
                  onNotConfirm={() => setDeleteTaskModal(false)}
                  modalIsOpen={deleteTaskModal}
                  setModalIsOpen={setDeleteTaskModal}
                  message="Vill du oaktivera aktivitet?"
                />
                <div
                  style={{
                    width: '150px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {' '}
                  <span className="place-middle">
                    <MdTask
                      className="project-screen__workers-parent__icon"
                      size={22}
                      color={themeColor}
                    />

                    <div>{r?.name as string}</div>
                  </span>
                  <span>
                    <BsTrash
                      size={18}
                      className="icon"
                      color={themeColor}
                      onClick={() => setDeleteTaskModal(true)}
                    />
                  </span>
                </div>
              </div>
            )
          })}
          <div>
            <ul style={{ margin: 0, padding: 0 }}>
              <div>
                <ModalComponent
                  title="Lägg till personal"
                  setIsModalOpen={() => openWorkersModal(!workersModal)}
                  isModalOpen={workersModal}
                >
                  <div
                    style={{
                      minWidth: '300px',
                      maxHeight: '700px',
                      overflowY: 'scroll',
                    }}
                  >
                    <div className="project-name-parent">
                      <AiFillProject
                        color={themeColor}
                        size={23}
                        className="project-screen__icon"
                      />
                    </div>
                    {workers && workers?.length > 0 ? (
                      <div>
                        <label>Nuvarande perosnal:</label>
                        <div>
                          {workers!.length > 0
                            ? workers!.map((r: User) => {
                                return (
                                  <div key={r.id}>
                                    <div>{r?.username as string}</div>
                                  </div>
                                )
                              })
                            : null}
                        </div>
                      </div>
                    ) : null}

                    <AddWorkerForm
                      usersArray={usersArray?.getUsersByCompany as User[]}
                      theme={themeColor}
                      onAdd={onAddWorker}
                      workers={workers ? workers : []}
                    />
                  </div>
                  <Button
                    label="Lägg till personal"
                    onClick={() =>
                      addUserToTask(
                        task?.getTaskByName?._id as string,
                        workers?.map((r) => r.id) as string[]
                      )
                    }
                    width="100%"
                    type="submit"
                    loading={addedUserLoading}
                    success={addUserSuccess}
                    icon={<IoAdd />}
                  />
                  {addUserSuccess ? (
                    <Info type="success">Användare tillagd!</Info>
                  ) : null}
                </ModalComponent>
              </div>
            </ul>
            <div style={{ marginTop: '1rem' }}>
              <h3 className="place-middle" style={{ width: 'fit-content' }}>
                <HiUsers color={themeColor} style={{ marginRight: '0.2rem' }} />
                <span>Användare</span>
              </h3>{' '}
              {task?.getTaskByName && task?.getTaskByName?.workers?.length
                ? task?.getTaskByName?.workers?.map((worker) => (
                    <span
                      className="place-middle"
                      style={{ width: 'fit-content' }}
                      key={worker?.id}
                    >
                      <span>
                        <HiUser
                          className="project-screen__workers-parent__icon"
                          size={22}
                          color={themeColor}
                        />
                      </span>
                      <label>{worker?.username ?? worker?.email} </label>
                    </span>
                  ))
                : '_'}
            </div>
          </div>
        </div>
        {subTaskDeactivated ? <Info type="success">Avaktiverat</Info> : null}
             {deactivateSubTaskError ? <Info type="error">Unexpected error happend</Info> : null}
      </div>
    </div>
  )
}
export default Task
