export function convertLeavesNames(input: string) {

    switch (input) {
        case 'totalArbete':
            return 'Totalt Arbete'
            break
        case 'Tjenstledighet':
            return 'Tjänstledighet'
            break
        case 'totalSjuk':
            return 'Total sjukfrånvaro'
            break
        case 'totalSemester':
            return 'Total semester'
            break
        case 'totalVabb':
            return 'Total vabb'
            break
        case 'totalTjenstledighet':
            return 'Total tjänstledighet'
            break

        default:
            return input
            break
    }
}