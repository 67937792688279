import CalendarNavigation from 'components/CalendarNavigation'
import Info from 'components/Info'
import Label from 'components/Label'
import LeaveCard from 'components/LeaveCard'
import LeaveStatusInfo from 'components/LeaveStatusInfo'
import LoadingSkeleton from 'components/LoadingSkeleton'
import ModalComponent from 'components/Modal'
import BasicSelect from 'components/Select'
import convertToLocalDate from 'functions/convertToLocalDate'
import getColorsByType from 'functions/getColorsByType'
import getLeaveStatusInfo from 'functions/getStatusInfoByType'
import { Leave } from 'generated/graphql'
import withMui from 'hoc/withMuiTheme'
import useAuth from 'hooks/Auth.hook'
import useTime from 'hooks/Time.hook'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { MdClose, MdFilterAltOff } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  LeaveStatusEnum,
  LeavesVariationsEnum,
  TimesVariationsEnum,
} from 'types/sharedTypes'
import './LeavesReview.styles.scss'

function LeavesRview() {
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const [selectedLeave, setSelecedLeave] = useState<Leave>()
  const [statusQuery, setStatusQuery] = useState('')
  const [leaveReviews, setLeaveReviews] = useState<Array<Leave>>([])
  const [typeQuery, setTypeQuery] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const {
    userLeavesQuery: [
      getLeaves,
      { data: userLeaves, loading: userleavesLoading, error: userLeavesError },
    ],
  } = useTime()
  const { user, userLoading } = useAuth()

  const goToPreviousWeek = () => {
    setCurrentMonth((prev) => prev.clone().subtract(1, 'month'))
  }
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const goToNextWeek = () => {
    setCurrentMonth((prev) => prev.clone().add(1, 'month'))
  }

  async function fetchData() {
    await getLeaves({
      variables: {
        userId: user?.currentUser?.id as string,
        beginDateQuery: moment(currentMonth).startOf('month'),
        finishDateQuery: moment(currentMonth).endOf('month'),
      },
      onCompleted: async (res) => {
        await setLeaveReviews(res.getUserLeaves as Leave[])
        setTimeout(() => {
          if (res.getUserLeaves?.length === 0) {
            setError(`
OBS! Hittades ingen frånvaro för ${moment(currentMonth).format('MMMM')}`)
          }
        }, 0)
      },
    })
  }
  useEffect(() => {
    if (user?.currentUser?.id && !userLoading) {
      setError('')
      fetchData()
    }

    if (!user?.currentUser && !userLoading) {
      navigate('/')
    }
  }, [user, currentMonth])
  useEffect(() => {
    const filteredLeves = userLeaves?.getUserLeaves
      ? statusQuery && typeQuery
        ? (userLeaves.getUserLeaves as Leave[]).filter(
            (r) =>
              r.status?.toString() === statusQuery &&
              r.leaveType?.toString() === typeQuery
          )
        : statusQuery
        ? (userLeaves.getUserLeaves as Leave[]).filter(
            (r) => r.status?.toString() === statusQuery
          )
        : typeQuery
        ? (userLeaves.getUserLeaves as Leave[]).filter(
            (r) => r.leaveType?.toString() === typeQuery
          )
        : (userLeaves.getUserLeaves as Leave[])
      : []
    setLeaveReviews(filteredLeves)
  }, [typeQuery, statusQuery])

  function onMoreInfo(leave: Leave) {
    setSelecedLeave({ ...leave })
    setModalIsOpen(true)
  }
  return (
    <div className="leaves-review-container">
      <Label label="Frånvaro" />
      <CalendarNavigation
        goToNextWeek={goToNextWeek}
        goToPreviousWeek={goToPreviousWeek}
        currentDate={currentMonth}
        type="month"
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          maxWidth: '1920px',
          margin: 'auto',
        }}
      >
        {' '}
        <div className="select-wrapper" style={{ width: '100%' }}>
          {userLeaves?.getUserLeaves && (
            <BasicSelect
              value={statusQuery}
              label="Status"
              defaultInput="Status"
              values={[...Object.values(LeaveStatusEnum)]
                .filter((r) => typeof r == 'string')
                .map((key, i) => ({
                  name: key as string,
                  _id: i,
                }))}
              handleChange={(e) => setStatusQuery(e.target.value)}
            />
          )}
          {userLeaves?.getUserLeaves && (
            <BasicSelect
              value={typeQuery}
              label="Fronvaråtyp"
              defaultInput="Frånvarotyp"
              values={[...Object.values(LeavesVariationsEnum)]
                .filter((r) => typeof r == 'string')
                .map((key, i) => ({
                  name: key as string,
                  _id: i,
                }))}
              handleChange={(e) => setTypeQuery(e.target.value)}
            />
          )}
        </div>{' '}
        <MdFilterAltOff
          className="icon"
          size={25}
          onClick={() => {
            setStatusQuery('')
            setTypeQuery('')
          }}
        />
      </div>
      <ModalComponent
        isModalOpen={modalIsOpen}
        title="Frånvaro Status"
        bgColor="#282828"
        setIsModalOpen={() => !modalIsOpen}
      >
        <div className="modal-con">
          <LeaveStatusInfo leave={selectedLeave as Leave} />
          <div className="icon-cont"></div>

          <div>
            <MdClose
              color="white"
              size={30}
              onClick={() => setModalIsOpen(false)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </ModalComponent>
      <div style={{ marginTop: '3rem' }}>
        {' '}
        {leaveReviews?.length && !userleavesLoading
          ? leaveReviews?.map((leave) => {
              const { ItemsIcon, statusColor } = getLeaveStatusInfo(
                leave?.status as string,
                leave?.leaveType as TimesVariationsEnum
              )
              const leaveColor = getColorsByType(leave?.leaveType as string)

              return (
                <div style={{ marginTop: '1rem', padding: '1rem' }}>
                  <LeaveCard
                    key={leave?._id as string}
                    id={leave?._id as string}
                    statusColor={statusColor}
                    type={leave?.leaveType as string}
                    icon={ItemsIcon}
                    beginDate={convertToLocalDate(leave?.beginDate)}
                    finishDate={convertToLocalDate(leave?.finishDate)}
                    onClick={onMoreInfo}
                    leave={leave as Leave}
                    leaveColor={leaveColor}
                  />
                </div>
              )
            })
          : null}
      </div>

      {(error || userLeavesError) && !userleavesLoading && (
        <Info type="warning" label="">
          {error ?? 'Unexpected error happend!'}
        </Info>
      )}

      {userleavesLoading && (
        <LoadingSkeleton
          numberOfColumns={3}
          heightOfColumn={50}
        ></LoadingSkeleton>
      )}
    </div>
  )
}
export default withMui(LeavesRview)
