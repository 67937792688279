import Button from 'components/Button'
import Info from 'components/Info'
import Input from 'components/Input'
import SimpleLoading from 'components/Loading'
import useAuth from 'hooks/Auth.hook'
import { ChangeEvent, FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import './Login.scss'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isFormValid, setIsFormValid] = useState(true)
  const [error, setError] = useState('')

  const { signIn, loginLoading, userData, loginError, currentUserError } =
    useAuth()

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)

    setError('')
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)

    setError('')
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setError('')
    // Basic email format validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValid = emailPattern.test(email)
    if (email === '' && !isEmailValid) {
      setIsFormValid(false)
      setError('Ej giltig e-postadress')
    } else if (password === '') {
      setIsFormValid(false)
      setError('Fykk i ditt lösenord')
    } else {
      setIsFormValid(true)
      setError('')
      try {
        await signIn({ email, password }, 10)
      } catch (error) {
        setIsFormValid(false)
        setError('Oväntat fel')
      }
    }
  }

  return (
    <div className="login-container">
      <h1>Logga in</h1>
      <label className="login-container__label">Välkommen tillbaka!</label>
      <form onSubmit={handleSubmit}>
        <span style={{ marginBottom: '0.5rem', width: '100%' }}>
          {' '}
          <Input
            placeholder="Skriv din E-postadress"
            label="E-mail"
            value={email}
            handleChange={handleEmailChange}
          />
        </span>

        <Input
          type="password"
          placeholder="Skriv ditt Lösenord"
          label="Lösenord"
          value={password}
          handleChange={handlePasswordChange}
        />
        {loginLoading && <SimpleLoading />}
        {loginError && !loginLoading && (
          <Info type="error">{'Användarnamn eller lösenord är ogiltigt'}</Info>
        )}
        {error && <Info type="error">{error}</Info>}
        {userData?.login?.token && !loginLoading && (
          <Info type="success">Inlogning lyckades</Info>
        )}
        <Button
          type="submit"
          width="100%"
          label="Logga in"
          loading={loginLoading}
        />
      </form>
      <div className="forgot-password-container">
        <Link className="forgot-password-link" to="/auth/reset/password/null">
          Återställ lösenord?
        </Link>
      </div>
    </div>
  )
}
